<template>
  <div>
    <!-- 顶部搜索栏 -->
    <el-row>
      <el-col :xs="24" :sm="24" :lg="24">
        <div class="pad-container flx-row ali-c">
          <el-input placeholder="输入商品名称" class="search-input mr-10" v-model="searchObj.goodsName"></el-input>
            <el-select
            placeholder="请选择商品状态"
            class="search-input mr-10"
            v-model="searchObj.status"
          >
            <el-option
              v-for="(item, index) in commodity_status"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
          <el-select v-model="searchObj.merchantId" filterable placeholder="请选择商户" class="form-input-200 mr-10">
            <el-option
              v-for="item in merchantList"
              :key="item.merchantId"
              :label="item.merchantName"
              :value="item.merchantId">
            </el-option>
          </el-select>
          <el-button type="primary" icon="el-icon-search" class="ml-15" @click="search">查询</el-button>
          <el-button icon="el-icon-refresh" class="ml-15" @click="reset">重置</el-button>
        </div>
      </el-col>
    </el-row>
    <!-- 表格内容 -->
    <el-row class="mt-24">
      <el-col el-col :xs="24" :sm="24" :lg="24">
        <div class="pad-container">
    

          <!-- 门店列表 -->
          <el-tabs v-model="searchObj.storeId" @tab-click="changeTab" class='mt-20'>
            <el-tab-pane
              :label="item.storeName"
              :name="item.id+''"
              v-for="(item,index) in shopList"
              :key="index"
            >
            </el-tab-pane>
          </el-tabs>
          
                <!-- 操作栏 -->
          <div class="flx-row ali-c js-btw mt-20">
            <div class="flx-row ali-c">
              <router-link :to="{ name: 'Repast_Commodity_new', query: { flag: true, storeId: searchObj.storeId } }">
                <el-button class="ml-15" type="primary">添加商品</el-button>
              </router-link>
            </div>
          </div>
          
          <!-- 表格 -->
          <el-table class="mt-24 member-table" :data="tableData">
            <el-table-column type="index" :index="indexMethod" label="序号" width="100" align="center"></el-table-column>
            <el-table-column label="商品名称" prop="goodsName"></el-table-column>
            <el-table-column label="商户" prop="merchantName"></el-table-column>
            <el-table-column label="展示价" prop="originalPrice"></el-table-column>
            <el-table-column label="团购价" prop="actualPrice"></el-table-column>
            <el-table-column label="核销截止日期" prop="writeOffEndTime"></el-table-column>
            <el-table-column label="剩余库存" prop="stock">
            </el-table-column>
             <el-table-column label="排序" prop="sortNum">
                <!-- <template slot-scope="scope">
                <div>{{scope.row.sortNum}} <i class='ml-10 el-icon-edit-outline tip-text-delete txt-tip-info' @click='openEdit(scope.row)' /></div>
              </template> -->
             </el-table-column>
            <el-table-column label="状态">
              <template slot-scope="scope">
                <el-switch
                  v-model="scope.row.status"
                  :active-value="1"
                  :inactive-value="0"
                  active-text="上架"
                  inactive-text="下架"
                  @change="upDownShelf($event, scope.row.id)"
                ></el-switch>
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <div class="flx-row ali-c">
                  <router-link
                    :to="{
                      name: 'Repast_Commodity_new',
                      query: { id: scope.row.id, flag: false, storeId: scope.row.storeId }
                    }"
                    class="txt-tip-info"
                  >
                    <i class="el-icon-edit-outline"></i>
                    编辑
                  </router-link>
                  <span
                      class="ml-10 tip-text-delete"
                      @click="deleteCommodity(scope.row.id)"
                    >
                      <i class="el-icon-delete"></i>
                      删除
                  </span>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div class="flx-row ali-c js-c mt-24">
            <Pagination
              :total="total"
              :pageNum="pageNum"
              :pageSize="pageSize"
              :pageSizes="pageSizes"
              @changePage="getCommodityList"
              @syncPageData="syncPageData"
            />
          </div>
        </div>
      </el-col>
    </el-row>

    <!-- 编辑排序弹窗 start -->
    <el-dialog :visible.sync="dialog" :title="title" @close="cancel">
      <el-form :model="form" :rules="rules" ref="form" label-position='right' label-width='100px'>
        <el-form-item prop="sort" label="排序">
          <el-input v-model="form.sort" placeholder="请输入排序数字"></el-input>
        </el-form-item>
      </el-form>
      <div class="flx-row ali-c js-c mt-50">
        <el-button @click="cancel">取消</el-button>
        <el-button type="primary" class='ml-10' @click="confirm('form')">提交</el-button>
      </div>
    </el-dialog>
    <!-- 审核弹窗 end -->
  </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import { getStoreList } from "@/api/online_department_store/commodity_manage"
import { getProcudtList, productUpDownShelf, productDelete, getMerchantsList} from "@/api/repast/commodity_manage"
export default {
  name: "Online_commodity_list",
  components: {
    Pagination
  },
  data() {
    return {
      // 顶部搜索栏数据
      searchObj: {
        goodsName: '',
        status: '',
        storeId: '1',
        merchantId: '',
      },
      classificationList: [],
      store_list: [],
      merchantList: [],
      // 商品状态
      commodity_status: [
        {
          id: 1,
          name: "在售"
        },
        {
          id: 0,
          name: "已下架"
        }
      ],
      // 商品类目
      commodity_cate: [
        {
          id: 1,
          title: "奖品"
        },
        {
          id: 2,
          title: "优惠券"
        }
      ],
      form:{
        sort: ''
      },
      rules: {
        sort:[
          { required: true, message: "排序不能为空", trigger: 'change' },
          { pattern: /^[1-9]\d{0,}$/, message: "只能为数字", trigger: "change"}
        ],
      },
      dialog: false,
      activePart: "1",
      title: '编辑',
      shopList: [],
      // 表格数据内容
      tableData: [],
      total: 0,
      pageNum: 1,
      pageSize: 10,
      pageSizes: [10, 20, 30, 50, 100]
    };
  },
  mounted() {
    this.getStores()
  },
  methods: {
    getCommodityList(isRet) {
      if(isRet){
        this.pageNum = 1
        this.pageSize = 10
      }
      let data = { pageNum: this.pageNum, pageSize: this.pageSize, ...this.searchObj}
      // console.log(data)

      getProcudtList(data).then(res => {
        this.tableData = res.data.body.list;
        this.total = res.data.body.total;
      });
    },
    confirm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          // console.log(this.form.sort)
          this.dialog = false;
        } else {
          return false;
        }
      });
    },
    indexMethod(index){ // 自定义排序号
        return index  + (this.pageNum - 1) * this.pageSize + 1;
    },
    cancel() {
      this.$refs.form.resetFields();
      this.dialog = false;
    },
    openEdit(row) { // 编辑排序弹窗出现
      this.dialog = true;
      this.form = row;
    },
    // 获取门店列表
    async getStores() {
      await getStoreList().then(res => {
        this.shopList = res.data.body.list;
        this.searchObj.storeId = this.shopList[0].id.toString();
        this.getMerchantsListHandle()
      })

      this.getCommodityList(1);
    },
    //获取商户列表
    getMerchantsListHandle() {
      getMerchantsList(this.searchObj.storeId).then(res => {
          this.merchantList = res.data.body;
      });
    },
    // 切换tab
    changeTab(tab, event) {
      this.searchObj.storeId = tab.name;
      this.getMerchantsListHandle()
      this.getCommodityList(1);
    },
    // 搜索事件
    search() {
      this.getCommodityList(1);
    },
    // 重置搜索关键词
    reset() {
      this.searchObj = {
        goodsName: '',
        status: '',
        storeId: '1',
        merchantId: '',
      };
      this.getCommodityList(1);
    },
    // 会员多选事件
    tableSelect(e) {
      // // console.log(e);
      this.multiSeletction = e;
    },
    //上下架
    upDownShelf(status, id) {
      productUpDownShelf(id, status).then(() => {
        this.$message({
          type: "success",
          message: "操作成功!"
        });
        this.getCommodityList()
      })
    },
    deleteCommodity(id) {
      this.$confirm("此操作将永久删除该商品, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消"
      })
      .then(() => {
        productDelete(id).then(() => {
          this.$message({
            type: "success",
            message: "删除成功!"
          });
          this.getCommodityList()
        })
      })
      .catch(() => {
        this.$message({
          type: "info",
          message: "已取消删除"
        });
      });
    },
    syncPageData(data) {
      this.pageNum = data.num;
      this.pageSize = data.size;
    }
  }
};
</script>
<style lang="scss" scoped>
.member-table {
  .circle {
    width: 6px;
    height: 6px;
    border-radius: 50%;
  }
}
.txt-tip-info {
  color: #409eff;
}

</style>
